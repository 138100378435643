<template>
  <div>
    <h2
      class="u-color-grey-base u-font-size-5 u-spacing-mb-s u-font-weight-600 u-spacing-ml-l"
    >
      {{ widgetConfig.heading }}
    </h2>
    <div
      class="u-color-grey-lighter u-font-size-7 u-spacing-mb-l u-spacing-ml-l"
    >
      {{ widgetConfig['sub-heading'] }}
    </div>
    <div class="u-display-flex u-spacing-pb-l u-spacing-ml-l container">
      <div class="u-display-flex u-flex-direction-column">
        <div
          class="u-spacing-pb-s u-font-size-5 u-font-weight-600 u-color-grey-lighter"
        >
          {{ widgetConfig.namelabel }}
        </div>
        <div class="u-position-relative">
          <rb-input
            v-model="widgetConfig.nameValue"
            class="u-display-flex name-value u-flex-align-items-center"
          />
        </div>
      </div>
      <div
        class="u-display-flex u-flex-direction-column u-width-25 u-position-relative"
      >
        <div
          class="u-spacing-pb-s u-font-size-5 u-font-weight-600 u-color-grey-lighter"
        >
          {{ widgetConfig.inputLabel }}
        </div>
        <div class="u-display-flex u-flex-0 u-flex-align-items-center">
          <span
            class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
          >
            {{ currency }}
          </span>
          <input
            v-model="updatedInputValue"
            type="number"
            class="rb-input currency-input u-display-flex u-flex-align-items-center"
            :min="widgetConfig.min || 0"
            step="0.01"
            :max="widgetConfig.max || 100"
            autofocus
            @input="validation($event)"
          />
        </div>
        <div
          v-if="showError"
          class="errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small"
            :icon="'error-fill'"
          />
          <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
    <footer
      slot="footer"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-mt-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
    >
      <rb-button
        :click-fn="widgetConfig.apply"
        :text="widgetConfig.leftBtnLabel || 'Apply'"
        :size="'s'"
        :type="'filled'"
        :disabled="!formValid"
        class="u-spacing-mr-m u-cursor-pointer"
      />
      <rb-button
        :click-fn="widgetConfig.handleCancel"
        :text="widgetConfig.rightBtnLabel || 'Cancel'"
        :size="'s'"
        :type="'hollow'"
        class="u-color-grey-light u-cursor-pointer"
      />
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    config: {
      type: Function,
      default() {
        return () => {};
      }
    }
  },
  data() {
    return {
      widgetConfig: {},
      updatedInputValue: null,
      formValid: true,
      showError: false,
      errorMessage: '',
      currency: this.$currency
    };
  },
  created() {
    this.widgetConfig = this.config(this);
    this.updatedInputValue = { ...this.widgetConfig }.inputValue;
  },
  methods: {
    validation(event) {
      const inputVal = event.target.value;
      if (!inputVal) {
        return;
      }
      const value = Number(inputVal).toFixed(2);
      this.showError = false;
      this.formValid = false;
      const max = this.widgetConfig.max;
      const min = this.widgetConfig.min;
      if (!this.isNullOrUndefined(max) && !this.isNullOrUndefined(min)) {
        if (value > max || value < min) {
          this.errorMessage =
            'Absolute value should be between ' + min + ' to ' + max;
          this.showError = true;
          return;
        }
      } else if (!this.isNullOrUndefined(max)) {
        if (value > max) {
          this.errorMessage = 'Absolute value should be less than ' + max;
          this.showError = true;
          return;
        }
      } else if (!this.isNullOrUndefined(min)) {
        if (value < min) {
          this.errorMessage = 'Absolute value should be greater than ' + min;
          this.showError = true;
          return;
        }
      }
      this.errorMessage = '';
      this.formValid = !this.errorMessage;
    },
    isNullOrUndefined(val) {
      if (val === null || val === undefined) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="css" scoped>
.name-value {
  width: 16.5rem;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.container {
  gap: 4rem;
}
.errorMessage {
  margin-top: 4px;
  position: absolute;
  bottom: -2rem;
}
.errorMessage .u-spacing-mt-xxxs {
  margin-top: 1px;
}
.errorMessage .rb-icon {
  align-self: flex-start;
}
.errorMessage .name-value {
  width: 16.5rem;
}
.errorMessage .rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.errorMessage .rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.errorMessage .rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.errorMessage .rb-input::-moz-placeholder {
  color: #caccce;
}
.errorMessage .rb-input:-ms-input-placeholder {
  color: #caccce;
}
.errorMessage .rb-input:-moz-placeholder {
  color: #caccce;
}
.errorMessage .appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.errorMessage .appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.errorMessage .container {
  gap: 4rem;
}
.errorMessage .errorMessage {
  margin-top: 4px;
  position: absolute;
  bottom: -2rem;
}
.errorMessage .errorMessage .u-spacing-mt-xxxs {
  margin-top: 1px;
}
.errorMessage .errorMessage .rb-icon {
  align-self: flex-start;
}
</style>
